/**
 * Created by wangtong on 2021/12/12
 */
const OSS_PREFIX = "https://cdn-oss-online.sandanapp.com/website/official/"

const MobileImages = {
	logo_0: OSS_PREFIX + "mobile/logo-0.png",
	logo_5: OSS_PREFIX + "mobile/logo-5.png",
	mobile_1: OSS_PREFIX + "mobile/mobile-1.png",
	mobile_3: OSS_PREFIX + "mobile/mobile-3.png",
	mobile_4: OSS_PREFIX + "mobile/mobile-4.png",
	apple: OSS_PREFIX + "mobile/apple.png",
	android: OSS_PREFIX + "mobile/android.png",
	arrow: OSS_PREFIX + "mobile/arrow.png",
	background_0: OSS_PREFIX + "mobile/background-0.png",
	background_3: OSS_PREFIX + "mobile/background-3.png",
	background_4: OSS_PREFIX + "mobile/background-4.png",
	background_5: OSS_PREFIX + "mobile/background-5.png"
}

const PcImages = {
	ios_qr_code: OSS_PREFIX + "pc/ios-qrcode.png",
	android_qr_code: OSS_PREFIX + "pc/android-qrcode.png",
	top_logo: OSS_PREFIX + "pc/top-logo.png",
	splat_top_logo: OSS_PREFIX + "pc/splat-top-logo.png",
	bottom_logo: OSS_PREFIX + "pc/bottom-logo.png",
	splat_phone_0: OSS_PREFIX + "pc/splat-phone-0.png",
	splat_phone_1: OSS_PREFIX + "pc/splat-phone-1.png",
	splat_phone_2: OSS_PREFIX + "pc/splat-phone-2.png",
	mobile_1: OSS_PREFIX + "pc/mobile-1.png",
	mobile_2: OSS_PREFIX + "pc/mobile-2.png",
	mobile_3: OSS_PREFIX + "pc/mobile-3.png",
	apple: OSS_PREFIX + "pc/apple.png",
	android: OSS_PREFIX + "pc/android.png"
}


export {
	MobileImages,
	PcImages
};

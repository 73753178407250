/**
 * Created by wangtong on 2021/12/12
 */

const IOS_APP_SCHEMA = "sandan://"
const ANDROID_APP_SCHEMA = "sandan://"
const IOS_APP_STORE = "https://apps.apple.com/cn/app/%E6%95%A3%E5%BC%B9/id1628169104";
const ANDROID_APP_APK = "https://cdn-oss-online.sandanapp.com/apk/official/sandan.apk";
const ICP_NUMBER = 19057660;


const IOS_PUBLISHED = true; // IOS是否上线了
const IOS_TOAST_MESSAGE = "IOS端还在开发中哦";

const ANDROID_PUBLISHED = true; // 安卓是否上线了


const Policy = {
	user_agreement: "https://letsplat.com/docs/agreement.html", // 用户服务协议
	privacy: "https://letsplat.com/docs/privacy.html" // 隐私政策协议
}


export {
	IOS_APP_SCHEMA,
	ANDROID_APP_APK,
	IOS_APP_STORE,
	ANDROID_APP_SCHEMA,
	ICP_NUMBER,
	IOS_PUBLISHED,
	IOS_TOAST_MESSAGE,
	ANDROID_PUBLISHED,
	Policy
};

export * from "./images"
export * from './device'
